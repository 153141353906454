import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import SEO from "../../components/SEO"
import Layout from "../../components/Layout"
import Header from "../../components/Header"
import Card from "../../components/Card"
import WhatToDoNav from "../../components/WhatToDoNav"
import PalmsImage from "../../components/PalmsImage"
import UmbrellaImage from "../../components/UmbrellaImage"
import { smallContainer } from "../../components/container"
import { colors } from "../../theme"

// Hero Section
const hero = css`
  position: relative;
`
const StyledCard = styled(Card)`
  position: relative;
  z-index: 10;
  margin: 50px auto;
`

const heroHeadline = css`
  font-size: 36px;
  position: relative;
  top: -25px;
  margin-bottom: 0;
`

const heroText = css`
  font-size: 18px;
  line-height: 1.3;
  color: ${colors.roseDark};
  max-width: 600px;
  margin: 20px 0;
`

const umbrellaImage = css`
  position: absolute;
  z-index: 0;
  top: -82px;
  left: 0;
  width: 140px;
`
const palmsImage = css`
  position: absolute;
  z-index: 0;
  top: -165px;
  right: -65px;
  width: 280px;
`

const WhatToDo = () => (
  <Layout>
    <SEO title="What to do" />
    <Header />
    <div css={[smallContainer, hero]}>
      <StyledCard>
        <h1 css={heroHeadline}>What to do in Palm Springs</h1>
        <p css={heroText}>
          We are so humbled by family and friends that are making the trip out
          to Palm Springs in support of our special day. We want to encourage
          you all to make the best of your vacation. For an unforgettable
          weekend &mdash; Click on the titles for links to activities in Palm
          Springs for the adventurers in the group!
        </p>
        <WhatToDoNav />
      </StyledCard>
      <div css={umbrellaImage}>
        <UmbrellaImage />
      </div>
      <div css={palmsImage}>
        <PalmsImage />
      </div>
    </div>
  </Layout>
)

export default WhatToDo
